export const JOB_POSITION_LIST = [
  { value: 'APP_DESIGNER', label: 'App Designer/Developer' },
  { value: 'MONETIZATION', label: 'Monetization' },
  { value: 'USER_ACQUISITION', label: 'User Acquisition' },
  { value: 'DATA_SCIENTIST', label: 'Data Scientist/Analyst' },
  { value: 'BUSINESS_DEVELOPMENT', label: 'Business Development' },
  { value: 'MANAGEMENT', label: 'Management' },
  { value: 'OTHER', label: 'Other' },
  { value: 'CREATIVE', label: 'Creative' },
  { value: 'PRODUCT', label: 'Product' },
];
