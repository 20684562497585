import { COUNTRY_LIST } from './country-list';
import { JOB_POSITION_LIST } from './job-position-list';

export const SITE_URL =
  process.env.SITE_URL || process.env.NEXT_PUBLIC_SITE_URL;

export const SITE_TITLE = 'Liftoff';

export const API_ENDPOINT =
  process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_ENDPOINT;

export const APP_STAGE =
  process.env.APP_STAGE || process.env.NEXT_PUBLIC_APP_STAGE;

export const REQUEST_HEADERS = { 'vungle-version': 1 };

// Reference regarding to RECAPTCHA_V3: https://www.npmjs.com/package/recaptcha-v3
export const RECAPTCHA_V3_SITE_KEY =
  process.env.RECAPTCHA_V3_SITE_KEY ||
  process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY ||
  '';

export const RECAPTCHA_V3_OPTIONS = {
  useRecaptchaNet: true,
  useEnterprise: false,
  autoHideBadge: false,
  renderParameters: {},
  explicitRenderParameters: {},
};

export const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;

export const LOGIN_REDIRECTION_URLS = {
  supply_self: `${process.env.NEXT_PUBLIC_PUB_SS}/auth`,
  supply_ctrl: `${process.env.NEXT_PUBLIC_PUB_CTRL}/auth`,
  demand_self: `${process.env.NEXT_PUBLIC_ADV_SS}/auth`,
  demand_ctrl: `${process.env.NEXT_PUBLIC_ADV_CTRL}/auth`,
};

export const JSON_LINKING_DATA = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Vungle Inc, 1255 Battery Street, Suite 500',
    addressLocality: 'San Francisco',
    addressRegion: 'California',
    postalCode: '94111',
  },
  logo: '',
  email: '',
  telephone: '',
  name: 'Vungle',
  url: 'https://vungle.com',
};

export { COUNTRY_LIST, JOB_POSITION_LIST };
