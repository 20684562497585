import * as Sentry from '@sentry/nextjs';
import { PRODUCTION, DEVELOPMENT } from './configs/constants';

// Configuring client side Sentry basic options
// Reference: https://docs.sentry.io/platforms/javascript/configuration/options/

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const IS_DEBUG =
  process.env.NEXT_PUBLIC_APP_STAGE === PRODUCTION ? false : true;
const STAGE = process.env.NEXT_PUBLIC_APP_STAGE;

if (SENTRY_DSN && STAGE !== DEVELOPMENT) {
  initSentry();
}

function initSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    debug: IS_DEBUG,
    environment: STAGE,
    sampleRate: 1.0,
    attachStacktrace: 'off',
    denyUrls: '',
    allowUrls: '',
    initialScope: {
      tags: { APP: 'Vungle-SSO' },
    },
  });
}
