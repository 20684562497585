import React, { useEffect } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { APP_STAGE } from '@/configs/index';
import { DEVELOPMENT } from '@/configs/constants';

import '@/styles/globals.css';

// load axe at development env for accessibility checking notification at console.log
if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  const React = require('react');
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  const config = require('../axe.config');
  axe(React, ReactDOM, 1000, config.default);
}

function MyApp({ Component, pageProps, err }) {
  const router = useRouter();

  if (typeof window !== 'undefined') {
    // Target to fix unclear onhandledrejection messages issue in Sentry
    // Reference: https://vungle.atlassian.net/browse/PAB-3099
    window.addEventListener('unhandledrejection', async (event) => {
      await import('@sentry/nextjs').then((Sentry) => {
        const rejectionReason = event?.reason
          ? event.reason
          : JSON.stringify(event);
        const captureMessage = `unhandledrejection reason: ${rejectionReason}`;
        Sentry.captureException(captureMessage);
      });
    });

    window.addEventListener('error', async (event) => {
      await import('@sentry/nextjs').then((Sentry) => {
        const errorMessage = event?.message
          ? event.message
          : JSON.stringify(event);
        const captureMessage = `onerror message: ${errorMessage}`;
        Sentry.captureException(captureMessage);
      });
    });
  }

  useEffect(() => {
    const handleRouteChangeComplete = (url) => {
      if (APP_STAGE !== DEVELOPMENT) {
        // track url with Segment when route changes complete
        window.analytics.page(url);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
  }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} err={err} />
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  err: PropTypes.object,
};

export default MyApp;
